<template>
  <div v-if="accept">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div>
          <b-button
            v-for="ev in evtList"
            :key="ev.id"
            variant="primary"
            style="margin: 2px 2px;"
            :href="`/congress/${ev.event.congress.path_event}/${ev.event.slug}`"
          >
            Transmissão das {{ ev.event.start_time }}
          </b-button>
        </div>
        <!-- VMIX -->
        <div v-if="eventSpeaker.password_vmix && sessionInfo.user.role === 'SPEAKER'">
          <div v-if="!(sameDevice)">
            Conectando em {{ 10 - timer }} ...
          </div>
          <iframe
            v-if="sameDevice"
            :src="`https://www.vmixcall.com/call.aspx?Key=${eventSpeaker.password_vmix}&Name=${eventSpeaker.speaker.name_badge}`"
            width="100%"
            height="500"
            allow="camera;microphone;fullscreen"
            style="border:none;"
            allowfullscreen=""
          />
        </div>
        <!-- IFRAMES -->
        <div v-else>
          <div
            v-if="live !== null"
          >
            <iframe
              v-if="live.type_link === 'cloudflare'"
              id="cloudflare"
              :src="vimeo_base + link + control"
              style="left: 0px; width: 100%; border: 0"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
              loop="true"
            />
            <vue-plyr
              v-if="live.type_link === 'youtube'"
              :options="options"
            >
              <div
                data-plyr-provider="youtube"
                :data-plyr-embed-id="link"
                allow="autoplay"
              />
            </vue-plyr>
            <iframe
              v-if="live.type_link === 'vimeo'"
              :src="'https://player.vimeo.com/video/'+live.link+'?autoplay=true&loop=false&byline=false&portrait=false&title=false&speed=true'"
              frameborder="0"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
              allowfullscreen
              style="overflow:hidden;height:410px;width:100%"
              height="410px"
              width="100%"
            />
          </div>
        </div>
        <!-- PALESTRANTES -->
        <b-card>
          <div v-if="speakers.length > 0">
            <CarouselInterval :speakers="speakers" />
          </div>
        </b-card>
      </div>
      <div class="col-lg-4 col-md-12">
        <chat
          :live-id="live_id"
          :user-id="sessionInfo.user.id"
          style="margin:0px;z-index: 999999;"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { BLink } from 'bootstrap-vue'
import chat from '../../../components/chat/Chat.vue'
import CarouselInterval from '@/components/carousel/CarouselInterval.vue'
import { mapActions } from 'vuex'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  components: {
    CarouselInterval,
    chat
    // BLink,
  },
  data() {
    return {
      deviceId: null,
      sameDevice: false,
      timer: 0,
      accept: false,
      speakers: [],
      live_id: null,
      vimeo_base: 'https://iframe.videodelivery.net/',
      control: '?preload=true&loop=true&autoplay=true',
      link: null,
      live: null,
      evtList: [],
      verifyDevice: false,
      options: {
        autoplay: true,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
      },
      time_in_live: 0,
      youtube_base: 'https://www.youtube.com/embed/',
      youtube_config:
            '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0',
    }
  },
  computed: {
    congress() {
      return this.$store.state.congress.congress
    },
    event() {
      return this.$store.state.congress.event
    },
    eventSpeaker() {
      return this.$store.state.congress.eventSpeaker
    },
    device() {
      return this.$store.state.congress.deviceId
    },
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
    speakerEvent() {
      return this.$store.state.congress.speakerEvents
    }
  },
  watch: {
    async event() {
      if (this.event) {
        if (this.sessionInfo.user.role === 'SPEAKER') {
          await this.ActionGetEventSpeakerByEvent({
            event_id: this.event.id,
            device_id: this.deviceId
          })
          if (typeof this.eventSpeaker === 'object') {
            if (this.eventSpeaker.password_vmix) {
              // check device id
              this.verifyDevice = true
              if (this.deviceId === this.eventSpeaker.device_id) {
                this.sameDevice = true
                this.accept = true
              } else {
                this.sameDevice = false
                this.$bvModal
                  .msgBoxConfirm('Caso você abra esta mesma tela em outro dispositivo, a janela de vídeo poderá ser desconectada automaticamente.')
                  .then(value => {
                    console.log(value)
                    if (value) {
                    // load the speaker vmix
                    // update device id
                      this.ActionGetDeviceId({
                        event_id: this.event.id,
                        device_id: this.deviceId
                      })
                      this.accept = true
                      this.sameDevice = false
                    } else {
                      window.location.href = '/'
                    }
                  })
              }
            } else {
              this.accept = true
            }
          } else {
            this.accept = true
          }
        } else {
          this.accept = true
        }
        const live = await this.ActionGetLiveInfo({ slug: this.event.slug })
        console.log('executou live')
        this.live = live
        this.live_id = live.id
        this.link = live.link
        this.speakers = this.event.speakers

        // monitoring
        this.createLiveMonitoring()
      }
    },
    device() {
      if (this.deviceId === this.device) {
        this.sameDevice = true
      } else {
        this.sameDevice = false
      }
    },
    speakerEvent() {
      // eslint-disable-next-line no-alert

      const q = new Date()
      const m = q.getMonth() + 1
      const d = q.getDate()
      const y = q.getFullYear()

      const date = new Date(`${y}-${m}-${d}`)

      this.speakerEvent.forEach(element => {
        const evtDate = new Date(element.event.date)

        if (element.event.slug !== this.$route.params.sluglive && evtDate.toString() === date.toString()) {
          this.evtList.push(element)
        }
      })
    },
    eventSpeaker() {
      console.log(this.eventSpeaker)
    }
  },
  async mounted() {
    await this.LoadEvent()
    this.ActionGetEventBySpeaker()

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      this.time_in_live++
    }, 1000)

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      this.timer++
      if (this.timer === 10 && this.sessionInfo.user.role === 'SPEAKER' && this.verifyDevice === true) {
        this.ActionGetDeviceId({
          event_id: this.event.id,
        })
        this.timer = 0
      }
    }, 1000)

    const fpPromise = FingerprintJS.load();
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise
      const result = await fp.get()

      // This is the visitor identifier:
      const { visitorId } = result
      this.deviceId = visitorId
    })()

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      if (this.time_in_live === 12) {
        console.log()
        this.ActionLiveMonitoringUpdate({
          user_id: this.sessionInfo.user.id,
          live_id: this.live.id,
          seconds_in_live: this.time_in_live
        })
        this.time_in_live = 0
      }
    }, 1000)
  },
  methods: {
    ...mapActions('congress', [
      'ActionLoadCongress',
      'ActionLoadEvent',
      'ActionGetEventSpeakerByEvent',
      'ActionGetDeviceId',
      'ActionGetLiveInfo',
      'ActionGetEventBySpeaker'
    ]),
    ...mapActions('congressAdministrator', [
      'ActionLiveMonitoringCreate',
      'ActionLiveMonitoringUpdate'
    ]),
    async createLiveMonitoring() {
      this.body = {
        user_id: this.sessionInfo.user.id,
        live_id: this.live.id,
      }

      const api = await this.ActionLiveMonitoringCreate(this.body)
      console.log(api)
    },
    updateLiveMonitoringTime() {
      const u = this.sessionInfo.user.id
      const l = this.live.id
      const s = this.time_in_live

      const URL = `${process.env.VUE_APP_API}/monitoring/update-time/`

      const body = {}
      const headers = { type: 'application/json' }
      const blob = new Blob([JSON.stringify(body)], headers)

      navigator.sendBeacon(`${URL + u}/${l}/${s}`, blob)
    },
    LoadEvent() {
      try {
        this.ActionLoadEvent({ event_slug: this.$route.params.sluglive })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style>
@media screen and (max-width: 480px) {
    #cloudflare{
      min-height: 240px;
    }
}
@media screen and (min-width: 480px) and (max-width: 720px) {
    #cloudflare{
      min-height: 350px;
    }
}
@media screen and (min-width: 720px) and (max-width: 900px) {
    #cloudflare{
      min-height: 470px;
    }
}
@media screen and (min-width: 900px) and (max-width: 1100px) {
    #cloudflare{
      min-height: 500px;
    }
}
@media screen and (min-width: 1100px) and (max-width: 1300px) {
    #cloudflare{
      min-height: 390px;
    }
}
@media screen and (min-width: 1300px) and (max-width: 1500px) {
    #cloudflare{
      min-height: 380px;
    }
}
@media screen and (min-width: 1500px) and (max-width: 1700px) {
    #cloudflare{
      min-height: 470px;
    }
}
@media screen and (min-width: 1700px) and (max-width: 1800px) {
    #cloudflare{
      min-height: 520px;
    }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
    #cloudflare{
      min-height: 620px;
    }
}
@media screen and (min-width: 2000px) {
    #cloudflare{
      min-height: 620px;
    }
}
</style>
